<script lang="ts">
    import MobileToggle from "@components/controls/MobileToggle.svelte";

    let className: string | undefined;
    export {className as class};

    export let hrefHome: string;

    let scrollY = 0;
    $: useNavBackground = scrollY > 60;
</script>

<svelte:window bind:scrollY />

<nav id="main-nav" class={`${className} layout-frame`} class:with-bg={useNavBackground}>
    <div class="content">
        <a href={hrefHome} class="brand-logo">
            <img src="/img/logo-white.svg" width="30" height="30" alt="appocados brand logo" />
            <span>appocados</span>
        </a>
        <div class="links-desktop">
            <slot />
        </div>
        <div class="links-mobile">
            <MobileToggle />
        </div>
    </div>
</nav>

<style>
    nav {
        background-color: color-mix(in srgb, var(--color-secondary-950) 90%, transparent);
        backdrop-filter: blur(0.5rem);
        box-shadow: var(--shadow-md);
        box-sizing: border-box;
        color: var(--color-shade-50);
        display: flex;
        position: fixed;
        width: 100%;
        transition: all 300ms ease-out;
        z-index: var(--z-index-desktop-menu);
    }

    .content {
        box-sizing: border-box;
        display: flex;
        gap: var(--size-4);
        padding-top: var(--size-4);
        padding-bottom: var(--size-4);
        width: 100%;
        z-index: var(--z-index-desktop-menu);
    }
    .brand-logo {
        align-items: center;
        color: inherit;
        display: flex;
        flex-grow: 0;
        font-weight: bold;
        gap: var(--size-3);
        text-decoration: none;
    }
    .links-mobile,
    .links-desktop {
        display: flex;
        flex-grow: 1;
        gap: var(--size-4);
        justify-content: flex-end;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .links-desktop {
        display: none;
    }

    @media (--from-tablet) {
        .links-desktop {
            display: flex;
        }
        .links-mobile {
            display: none;
        }

        nav {
            background-color: transparent;
            backdrop-filter: none;
            box-shadow: none;
        }
        nav.with-bg {
            background-color: color-mix(in srgb, var(--color-secondary-950) 90%, transparent);
            backdrop-filter: blur(0.5rem);
            box-shadow: var(--shadow-md);
        }
    }
</style>
