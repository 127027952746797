<script>
    import {mobileMenuOpen as opened} from "@stores/mobile-menu-open";
    import IconFaBarsSolid from "@components/icons/IconFaBarsSolid.svelte";
    import IconFaXmarkSolid from "@components/icons/IconFaXmarkSolid.svelte";

    let className = "";
    export {className as class};
</script>

<button on:click={opened.toggle} class={className} class:no-tab-highlight={true}>
    {#if $opened}
        <IconFaXmarkSolid />
    {:else}
        <IconFaBarsSolid />
    {/if}
</button>

<style>
    button {
        align-items: center;
        background: transparent;
        border: none;
        border-color: var(--color-main);
        border-radius: var(--radius-xs);
        color: var(--color-main);
        cursor: pointer;
        display: inline-flex;
        font-size: var(--font-size-2xl);
        font-weight: var(--font-weight-bold);
        justify-content: center;
        line-height: var(--leading-none);
        padding: 0;
        text-decoration: none;
        transition: all ease-out 100ms;
        z-index: var(--z-index-mobile-toggle);
    }
</style>
